<template>
    <div>
        <div class="row mb-2 d-block d-sm-none">
            <div class="col-12">
                <a href="javascript:void(0)" v-on:click.prevent="$router.push({name:'BusinessProfile'})" class="text-dark"><fa icon="angle-left" /> Back</a>
                <span class="title ms-5" v-if="services">Book or Request a Quote</span>
            </div>
        </div>
        <div class="overview-onbox">
            <div class="services-accordion my-3">
                <!-- <div class="title" v-if="services">Book or Request a Quote</div> -->
                <div class="accordion" id="accordionExample" v-if="services">
                    <div class="accordion-item" v-for="(category,index) in services" :key="category.id" :class="category.business_service.length > 0 ? 'accordion-item' : 'accordion-item border-0'">
                        <h2 class="accordion-header" :id="'serv'+index" v-if="category.business_service.length > 0">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" :data-bs-target="'#collapse'+index" aria-expanded="true" :aria-controls="'collapse'+index">
                                {{category.title}}
                            </button>
                        </h2>
                        <div :id="'collapse'+index" class="accordion-collapse collapse" v-if="category.business_service.length > 0" :aria-labelledby="'serv'+index" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="row">
                                    <div class="col-6 px-1 px-md-3" v-for="service in category.business_service" :key="service.id">
                                        <router-link :to="{name:'BookNow',params:{slug:$route.params.slug},query:{service:service.id}}">
                                        <div class="queue-service">
                                            <div class="queue-title"><h4>{{service.title}}</h4></div>
                                            <div class="description"><p>{{service.description}}</p></div>
                                            <div class="time"><fa :icon="['far','clock']" /> {{service.duration}} mins</div>
                                            <div class="price">Price: <span class="regular-pr">$30.00</span> $15.00</div>
                                            <div class="add-to-list">
                                                <router-link v-if="service.is_requested == 0" :to="{name:'BookNow',params:{slug:$route.params.slug},query:{service:service.id}}" class="btn btn-outline-primary btn-sm px-4"> Book</router-link>
                                                <!-- <router-link v-else :to="{name:'BookNow',params:{slug:$route.params.slug},query:{service:service.id}}" class="btn btn-outline-primary btn-sm"> Request Now</router-link> -->
                                                <a href="#" v-else data-bs-toggle="modal" data-bs-target="#requestServiceModal" class="btn btn-outline-primary btn-sm">Request Now</a>
                                            </div>
                                        </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-else>
                    <div class="text-center p-md-5">
                        <img src="@/assets/images/services/no-service.svg" style="width: 150px">
                        <h3 class="fw-normal">No Services</h3>
                        <p>There are not any services provided by this business.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- The Modal -->
        <div class="modal fade" id="requestServiceModal">
            <div class="modal-dialog modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Request services</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <label class="mb-2">Select Services</label>
                            <div v-for="(service,index) in serviceRequest_.services" :key="index" class="form-group col-md-6">
                                <label class="con-company" :class="service.selected?'currnt':''">
                                    <span>{{service.title}}</span>
                                    <span class="float-end ms-5">${{service.cost}}</span>
                                    <span class="float-end">{{service.duration}} min</span>
                                    <input v-model="service.selected" :true-value="true" :false-value="false" type="checkbox" name="checkbox" v-on:change="getEmployees" />
                                    <span class="checkmark"></span>
                                </label>
                            </div>



                            <!-- <div v-for="(service,index) in serviceRequest_.services" :key="service.id" class="form-group col-sm-6 col-md-6">
                                <label><span v-if="index == 0">Select Services</span> </label>
                                <label class="con-company" :class="service.selected?'currnt':''">
                                    {{service.title}}
                                    <input v-model="service.selected" :class="[v$.serviceRequest_.services.$error ? 'border-danger': '']" :true-value="true" :false-value="false" type="checkbox" name="checkbox" v-on:change="getEmployees" />
                                    <span class="checkmark"></span>
                                </label>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="inputPassword4">Select Professional </label>
                                    <select v-model="serviceRequest_.employee" :class="[v$.serviceRequest_.employee.$error ? 'border-danger': '']" class="form-control">
                                        <option value="">Choose employee</option>
                                        <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-normal">Location Preference</label>
                                    <select class="form-select" v-model="serviceRequest_.render_location">
                                        <option value="1">At Business</option>
                                        <option value="2">My Location</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label>Date</label>
                                    <Datepicker :minDate="new Date()" v-model="serviceRequest_.request_date" :class="[v$.serviceRequest_.request_date.$error ? 'border-danger': '']" :enableTimePicker="false" :autoApply="true" placeholder="Select Date"></Datepicker>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label class="font-weight-normal">Requested price</label>
                                    <div class="input-group">
                                        <span class="input-group-text">$</span>
                                        <input disabled type="text" class="form-control" :class="[v$.serviceRequest_.price.$error ? 'border-danger': '']" v-model="serviceRequest_.price" placeholder="Enter price" >
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Request Description</label>
                                    <textarea v-model="serviceRequest_.description" class="form-control h-auto" :class="[v$.serviceRequest_.description.$error ? 'border-danger': '']" rows="4"  placeholder="Please add brief summary of your request here."></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="ond-btn-w" data-bs-dismiss="modal">Cancel</button>
                        <a href="#" class="ond-btn-p" v-on:click.prevent="makeBooking">Book now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import $ from 'jquery'
    import useVuelidate from '@vuelidate/core'
    import { required } from '@vuelidate/validators'
    import { useToast } from "vue-toastification"
    import Datepicker from '@vuepic/vue-datepicker';
    export default {
        props: {services:Array},
        setup () {
            const toast = useToast();
            return { v$: useVuelidate(),toast }
        },
        components: {
            Datepicker
        },
        created(){
            this.getServices()
        },
        data() {
            return {
                serviceRequest_:{
                    request_date:null,
                    description:'',
                    employee:'',
                    services:[],
                    render_location:1,
                    price:'',
                    slug:this.$route.params.slug
                },
                employees:[]
            };
        },
        validations () {
            return {
                serviceRequest_:{
                    request_date:{ required },
                    description:{ required },
                    employee:{ required },
                    services:{ required },
                    render_location:{ required },
                    price:{ required }
                }
            }
        },
        methods:{
            getServices(){
                let thiss = this
                axios.get('requestedServices/'+this.$route.params.slug).then(function (response) {
                    console.log(response.data)
                    if (response.data.status == 'success') {
                        let data = []
                        response.data.data.forEach((serv) => {
                            data.push({
                                service_id: serv.service.id,
                                title: serv.service.title,
                                business_id: serv.business_id,
                                business_service_id: serv.id,
                                cost: serv.cost,
                                duration: serv.duration,
                                selected: false
                            })
                        });
                        thiss.serviceRequest_.services = data
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            getEmployees(){
                let thiss = this
                axios.post('services_employees',{services:thiss.serviceRequest_.services}).then(function (response) {
                    //console.log(response.data.data)
                    thiss.employees = response.data.data
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
                this.serviceRequest_.employee = ''
                this.serviceRequest_.price = this.serviceRequest_.services.reduce(function (acc, item){
                    if(item.selected == true){
                        return (parseFloat(acc) + parseFloat(item.cost)).toFixed(2)
                    }
                },0);
            },
            /*servicesList(){
                let thiss = this
                axios.get('bookingServices/'+this.$route.params.slug+'/'+this.request_.render_location).then(function (response) {
                    if (response.data.status == 'success') {
                        thiss.services = response.data.data.services
                        if (thiss.request_.render_location == 0) {
                            thiss.request_.render_location = response.data.data.active_loc
                        }
                        console.log(response.data.data)
                    }
                })
                .catch(error => {
                    if (error.response) {
                        console.log(error.response.data.message)
                    }
                });
            },*/
            makeBooking(){
                console.log(this.serviceRequest_)
                this.v$.serviceRequest_.$touch()
                if (!this.v$.serviceRequest_.$error){
                    let thiss = this
                    axios.post('reuested_services_booking',this.serviceRequest_).then(function (response) {
                        console.log(response.data)
                        if (response.data.status == 'success') {
                            thiss.toast.success("Quotation successfully submitted.");
                            //thiss.$router.push({ name: 'quotations'})
                            $(".btn-close").click()
                        }else if (response.data.status == 'login') {
                            thiss.$storage.removeStorageSync("user")
                            thiss.$storage.removeStorageSync("token")
                            thiss.$storage.removeStorageSync("business")
                            thiss.$router.push({ name: 'Home'})
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                this.toast.error(error.response.data.message);
                            }else if (error.response.status == 401) {
                                this.toast.error(error.response.data.message,{position: "top-right"});
                            }
                        }
                    });
                }
            }
        },
        computed: {
            servicesTotal: function () {
                return this.serviceRequest_.services.reduce(function (acc, item){
                    if(item.selected == true){
                        return (parseFloat(acc) + parseFloat(item.cost)).toFixed(2)
                    }
                },0);
            }
            // servicesTax: function () {
            //     if (this.booking_.is_deal) {
            //         return this.booking_.deal.deal_services.reduce((acc, item) => (item.tax != null) ? parseFloat(acc) + parseFloat(item.business_service.tax) : parseFloat(acc)+0,0).toFixed(2);
            //     }else{
            //         return this.booking_.services.reduce((acc, item) => (item.tax != null) ? parseFloat(acc) + parseFloat(item.tax) : parseFloat(acc)+0,0).toFixed(2);
            //     }
            // },
            // total: function () {
            //     return (parseFloat(this.servicesTotal) + parseFloat(this.servicesTax)).toFixed(2)
            // }
        }
    }
</script>

<style>
    .accordion-button{
        font-size: 1.25rem
    }
    .accordion-body{
        padding: 1rem 0.75rem
    }
    .accordion-button::after{
        background-image: url('../../assets/images/pricing/down_black.svg') !important
    }
    .accordion-item{
        border: none
    }
    .btn-outline-secondary:hover {
        color: #fff
    }
    .price{
        color: #303030
    }
    .border-danger input{
        border-color: #dc3545 !important
    }
    .queue-service:hover{
        background-color: #fcfcfc
    }
    .btn-outline-primary:active {
    color: #fff;
    border-color: #632a6e !important;
    background-color: #632a6e !important;
    }
    .btn-outline-primary:active:focus {
    box-shadow: 0 0 0 0.25rem #632a6e;
    }
    @media screen and (max-width: 500px)
    {
        .queue-title h4 {
            font-weight: bold;
        }
    }
</style>
<template>
    <!-- <div class="container-fluid px-3 px-lg-5 border-bottom justify-content-center"> -->
        <!-- <div class="alert alert-success alert-dismissible fade show text-center mb-0 text-white border-dark rounded-0 py-2 d-none d-md-block" role="alert" v-if="broadcast.show_info_text == 1" style="font-size: 14px;background-color: #000000">
            <fa class="dropdown-icon me-0 text-white" :icon="['far','gem']" />
            {{broadcast.info_text}}
            <router-link :to="broadcast.info_url" class="alert-link ms-3 text-white border rounded p-1" v-if="broadcast.show_info_url == 1">{{broadcast.info_button_text}}</router-link>
            <button type="button" class="btn-close bg-white opacity-100 p-1 me-3" data-bs-dismiss="alert" aria-label="Close" style="font-size: 12px;margin-top: 9px"></button>
        </div> -->
    <!-- </div> -->
    <header class="container-fluid px-3 px-lg-5 border-bottom top_header">
      <!-- <div class="d-flex d-md-none flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
          <a href="#" class="d-flex align-items-center col-4 mb-2 animated-arrow"  onclick="openNav()">
              <span></span>
          </a>
          <div class="col-4 justify-content-center text-center"><img src="@/assets/images/logo.png" alt="OnDaQ" class="img-fluid" /></div>
          <div class="col-4 text-end">
              <button type="button" class="btn btn-sm btn-ondaq-dark btn-open-app"><img src="@/assets/images/icons/add-user.png">Open App</button>
          </div>
      </div> -->

      <div class="d-flex d-md-none flex-wrap align-items-center justify-content-center justify-content-md-between py-2">
          <a href="#" class="col-4 animated-arrow" v-on:click.prevent="openNav">
              <span></span>
          </a>
          <div class="col-4 justify-content-center"><router-link to="/"><img src="@/assets/images/logo.png" alt="OnDaQ" class="img-fluid" /></router-link></div>
          <div class="col-4 text-center ps-4">
            <!-- <div class="float-end user-dropdown pe-4" v-if="!$storage.getStorageSync('token')">
                <a href="#" class="col-4 animated-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                    <span></span>
                </a>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li><button v-if="!$storage.getStorageSync('token')" type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#loginModal"><fa class="dropdown-icon" icon="sign-in-alt" /> Sign In</button></li>
                  <li><button v-if="!$storage.getStorageSync('token')" type="button" class="dropdown-item" data-bs-toggle="modal" data-bs-target="#signupModal"><fa class="dropdown-icon" icon="user-plus" /> Sign Up</button></li>
                </ul>
            </div> -->

            <div v-if="$storage.getStorageSync('token')" class="float-end user-dropdown">
                <button type="button" class="position-relative border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
                    <img alt="" src="@/assets/images/profile/Anne_Hathaway_Face.jpg" class="img-fluid small-profile-pic">
                    <span class="online"></span>
                </button>
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                  <li v-if="$storage.getStorageSync('business').id > 0"><router-link :to="{name:'BusinessDashboard'}" target='_blank' class="dropdown-item text-purple"><fa class="dropdown-icon" :icon="['far','gem']" /> Go to Business Dashboard</router-link></li>
                  <!-- <li v-if="$storage.getStorageSync('business_id') > 0"><a href="business/services" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','gem']" /> Business Page</a></li> -->
                  <li><router-link :to="{name:'EditProfile'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','user']" />Dashboard</router-link></li>
                  <li><router-link :to="{name:'Chat'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','comments']" />Messages</router-link></li>
                  <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />Favorites</router-link></li>
                  <li><router-link :to="{name:'HelpCenter'}" class="dropdown-item"><fa class="dropdown-icon" icon="question-circle" />Help &amp; Support</router-link></li>
                  <!-- <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />Send a Feedback </router-link></li> -->
                  <!-- <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />English</router-link></li> -->
                  <li><a class="dropdown-item" href="#" v-on:click="logout"><fa class="dropdown-icon" icon="sign-out-alt" /> Sign Out</a></li>
                </ul>
            </div>
              
            <router-link to="/list-your-business" v-if="!$storage.getStorageSync('token')" style="font-weight: 500;font-size: 14px">Ondaq <p class="mb-0" style="margin-top: -5px">Business</p></router-link>
          </div>
      </div>
      <div class="d-md-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-2 d-none">
          <router-link to="/" class="d-flex align-items-center col-md-3 col-lg-2 mb-2 mb-md-0">
              <img src="@/assets/images/logo.png" alt="Logo" class="img-fluid" />
          </router-link>
          <ul class="nav col-12 col-md-5 col-lg-6 mb-2 justify-content-lg-end justify-content-center mb-md-0 centered-nav ">
              <!-- <li><router-link :to="{name:'/'}" class="nav-link px-2 mx-2 " v-on:click="isActive = !isActive" v-bind:class="{ active: isActive }" >Home</router-link></li> -->
              <!-- <li><router-link :to="{name:'Marketing'}" class="nav-link px-2 mx-2">Marketing</router-link></li> -->
              <li><router-link :to="{name:'OndaqBusiness'}" class="nav-link px-2 mx-2 ondaq_business"  >OnDaQ Business</router-link></li>
              <li><router-link :to="{name:'HowItWorks'}" class="nav-link px-2 mx-2" >How it Works</router-link></li>
              <li><router-link :to="{name:'Pricing'}" class="nav-link px-2 mx-2" >Pricing</router-link></li>
              <li><router-link :to="{name:'HelpCenter'}" class="nav-link px-2 mx-2" >Help?</router-link></li>
          </ul>
          <div class="col-md-4 col-lg-4 text-end mb-md-0 mt-md-1">
              <button v-if="!$storage.getStorageSync('token')" type="button" class="btn btn-login me-2" data-bs-toggle="modal" data-bs-target="#loginModal"><img src="@/assets/images/icons/user.png" class="img-fluid">Sign In</button>
              <button v-if="!$storage.getStorageSync('token')" type="button" class="btn btn-signup" data-bs-toggle="modal" data-bs-target="#signupModal"><img src="@/assets/images/icons/add-user.png" class="img-fluid">Sign Up</button>

              <div v-if="$storage.getStorageSync('token')" class="float-end user-dropdown">
                  <button type="button" class="position-relative border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
                      <img alt="" src="@/assets/images/profile/Anne_Hathaway_Face.jpg" class="img-fluid small-profile-pic">
                      <span class="online"></span>
                  </button>
                  <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <li v-if="$storage.getStorageSync('business').id > 0"><router-link :to="{name:'BusinessDashboard'}" target='_blank' class="dropdown-item text-purple"><fa class="dropdown-icon" :icon="['far','gem']" /> Go to Business Dashboard</router-link></li>
                    <!-- <li v-if="$storage.getStorageSync('business').has_business == 1"><a href="business/services" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','gem']" /> Business Page</a></li> -->
                    <li><router-link :to="{name:'EditProfile'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','user']" />Dashboard</router-link></li>
                    <li><router-link :to="{name:'Chat'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','comments']" />Messages</router-link></li>
                    <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />Favorites</router-link></li>
                    <li><router-link :to="{name:'HelpCenter'}" class="dropdown-item"><fa class="dropdown-icon" icon="question-circle" />Help &amp; Support</router-link></li>
                    <!-- <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />Send a Feedback</router-link></li> -->
                    <!-- <li><router-link :to="{name:'userFavourites'}" class="dropdown-item"><fa class="dropdown-icon" :icon="['far','heart']" />English</router-link></li> -->
                    <li><a class="dropdown-item" href="#" v-on:click="logout"><fa class="dropdown-icon" icon="sign-out-alt" /> Sign Out</a></li>
                  </ul>
              </div>
              <notification />
              <message-notification />

              <router-link to="/list-your-business" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('business').id == ''" type="button" class="btn btn-signup list-business px-lg-3 me-2 me-lg-3"><img src="@/assets/images/icons/plus.png" class="img-fluid m-0">List My Business</router-link>
          </div>
      </div>

      <div class="mobile-menu">
        <div id="menuSidepanel" class="menu-sidebar">
          <div class="m-nav">
              <div class="text-center py-4 border-bottom"><img src="@/assets/images/logo-small.png" alt="OnDaQ Logo"></div>
            <!-- <a href="javascript:void(0)" class="closebtn" v-on:click.prevent="closeNav">×</a> -->
            <div class="login-menu" v-if="!$storage.getStorageSync('token')">
                <a href="#" data-bs-toggle="modal" data-bs-target="#loginModal" v-on:click.prevent="closeNav"><fa class="dropdown-icon" icon="sign-in-alt" />Sign In</a>
                <a href="#" data-bs-toggle="modal" data-bs-target="#signupModal" v-on:click.prevent="closeNav"><fa class="dropdown-icon" icon="user-plus" />Sign Up</a>
            </div>
            <div class="login-menu" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('business').id == ''">
                <router-link to="/list-your-business"><fa icon="plus" style="font-size: 14px;"/> List My Business</router-link>
            </div>
            <div class="login-menu" v-if="$storage.getStorageSync('token') && $storage.getStorageSync('business').id != ''">
                <router-link :to="{name:'BusinessDashboard'}" target='_blank' class="text-purple"> Go to the dashboard</router-link>
            </div>
            <router-link :to="{name:'OndaqBusiness'}" class="mt-3 text-purple" >OnDaQ Business</router-link>
            <router-link :to="{name:'HowItWorks'}">How it Works</router-link>
            <router-link :to="{name:'Pricing'}">Pricing</router-link>
            <router-link :to="{name:'HelpCenter'}" class="text-purple" >Need Help?</router-link>
            <a href="#">Send a Feedback</a>
          </div>
        </div>
      </div>

    </header>


    <!-- Login Modal -->
    <div class="modal fade" id="loginModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="loginModalLabel"><img src="@/assets/images/logo-small.png" alt="OnDaQ Logo" class="img-fluid me-2" />Welcome back!</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <!-- <form @submit.prevent="login"> -->
                      <div class="row">
                        <div class="col-md-6 col-12 mb-2">
                          <a href="#!" class="btn-facebook">
                            <img src="@/assets/images/facebook.png" class="me-2">
                            <span>Continue with Facebook</span>
                          </a>
                        </div>
                        <div class="col-md-6 col-12 mb-2">
                          <a href="#!" class="btn-google">
                            <img src="@/assets/images/google.png" class="me-2">
                            <span>Continue with Google</span>
                          </a>
                        </div>
                        <!-- <div class="col-xs-12 col-sm-6">
                          <div class="btn-group btn-block mt-2 mb-2">
                              <a class="btn btn-facebook active" href="#">
                                <span class="fa fa-facebook"></span>
                              </a>
                              <a class="btn btn-block btn-facebook" href="#" >LogIn with Facebook</a>
                          </div>
                        </div>

                        <div class="col-xs-12 col-sm-6">
                          <div class="form-group">
                            <div class="submit-block text-center">
                              <button class="btn btn-primary btn-block">Sign In with Google</button>
                            </div>
                          </div>
                        </div>
                        <div class="col-12">
                          <img src="@/assets/images/img/social_signin.png" alt="Social Login" class="img-fluid">
                        </div> -->
                      </div>
                      <hr class="divider">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                        <input type="text" class="form-control" :class="[v$.login_.email.$error ? 'border-danger': '']" name="email" v-model="login_.email" placeholder="username@example.com">
                        <!-- <div v-if="v$.login_.email.$error && this.v$.login_.email.$errors[0].$validator == 'email'" class="form-error-msg-labeled">
                          <span v-if="this.v$.login_.email.$errors[0].$validator == 'email'">Invalid Email</span>
                        </div> -->
                      </div>
                      <div class="form-group">
                        <label class="form-label">Password</label>
                        <div class="input-group mb-3">
                          <input :type="passwordType" class="form-control" :class="[v$.login_.password.$error ? 'border-danger': '']" name="password" v-model="login_.password" placeholder="******">
                          <span class="input-group-text"><fa :icon="['far','eye']" v-show="passwordType=='text'" @click="switchVisibility" /><fa @click="switchVisibility" v-show="passwordType=='password'" :icon="['far','eye-slash']" /></span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xs-12 col-sm-6">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" value="" id="remember_me">
                            <label class="form-check-label" for="remember_me">Remember me</label>
                          </div>
                        </div>

                        <div class="col-xs-12 col-sm-6">
                          <a data-bs-dismiss="modal" @click.prevent="openModal('#ForgotPasswordModal')" href="#" class="form-text text-dark float-end"><small class="text-14">Forgot password?</small></a>
                        </div>
                      </div>
                      <div class="d-grid gap-2 mt-3">
                        <input v-on:click="login" value="Sign In" name="submit" class="btn btn-common" type="button" v-on:disabled="reqProcessing">
                        <p class="text-12 my-3">By clicking sign in and continue with Facebook or Google, it means you agree to our <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a> .</p>
                      </div>
                      <div class="text-center mt-3 text-dark text-16">Don't have account yet?</div>
                      <div class="d-grid gap-2 mt-3">
                        <button type="button" data-bs-dismiss="modal" class="btn outling-button" @click.prevent="openModal('#signupModal')">Sign Up</button>
                      </div>
                    <!-- </form> -->
                </div>
            </div>
        </div>
    </div>
    <!-- End Login Modal -->

    <!-- Register Modal -->
    <div class="modal fade" id="signupModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="signupModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="signupModalLabel"><img src="@/assets/images/logo-small.png" alt="OnDaQ Logo" class="img-fluid me-2" />Let's Get You Started</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form >
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">First Name</label>
                            <input type="text" class="form-control" name="fname" v-model="signup_.first_name" placeholder="John" :class="[v$.signup_.first_name.$error ? 'border-danger': '']">
                            <!-- <div v-if="v$.signup_.first_name.$error" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.first_name.$errors[0].$validator == 'required'">First name is required</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <input type="text" class="form-control" name="lname" v-model="signup_.last_name" placeholder="Doe" :class="[v$.signup_.last_name.$error ? 'border-danger': '']">
                            <!-- <div v-if="v$.signup_.last_name.$error" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.last_name.$errors[0].$validator == 'required'">Last name is required</span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <label class="form-label">Email</label>
                            <input type="email" class="form-control" :class="[v$.signup_.email.$error ? 'border-danger': '']" name="remail" v-model="signup_.email" placeholder="username@example.com">
                            <!-- <div v-if="v$.signup_.email.$error" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.email.$errors[0].$validator == 'email'">Invalid Email</span>
                              <span v-if="this.v$.signup_.email.$errors[0].$validator == 'required'">Email is required</span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">Password</label>
                            <input :type="passwordType" class="form-control" :class="[v$.signup_.password.$error ? 'border-danger': '']" name="rpassword" v-model="signup_.password" placeholder="******">
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">Confirm Password</label>
                            <input type="password" class="form-control" :class="[v$.signup_.c_password.$error ? 'border-danger': '']" name="cpassword" v-model="signup_.c_password" placeholder="******">
                            <div v-if="v$.signup_.c_password.$error && this.v$.signup_.c_password.$errors[0].$validator == 'sameAsPassword'" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.c_password.$errors[0].$validator == 'sameAsPassword'">Password don't match</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">Gender</label>
                            <select class="form-select" :class="[v$.signup_.gender.$error ? 'border-danger': '']" v-model="signup_.gender">
                              <option value="">Choose your Gender</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                            </select>
                            <!-- <div v-if="v$.signup_.gender.$error" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.gender.$errors[0].$validator == 'required'">Choose gender</span>
                            </div> -->
                          </div>
                        </div>
                        <div class="col-12 col-sm-6">
                          <div class="form-group">
                            <label class="form-label">Phone</label>
                            <vue-tel-input v-model="signup_.phone" @country-changed="updateCountryCode" :class="[v$.signup_.phone.$error ? 'border-danger': '']" :onlyCountries="['US','PK']" defaultCountry="US" v-mask="'(###) ### - ####'" :inputOptions="{type:'tel',placeholder:'(000) 000 - 0000'}" :dropdownOptions="{showDialCodeInList:true,showFlags:true,showDialCodeInSelection:true}" :autoFormat="false" mode="international"></vue-tel-input>
                            <!-- <div v-if="v$.signup_.phone.$error" class="form-error-msg-labeled">
                              <span v-if="this.v$.signup_.phone.$errors[0].$validator == 'required'">Phone number is required</span>
                            </div> -->
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 my-1">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="signup_.TandC" id="TermAndCond">
                            <label class="form-check-label" for="TermAndCond">By clicking create account, it means you agree to Ondaq’s <a href="#">Privacy Policy</a> and <a href="#">Terms of Service</a></label>
                            <div v-if="v$.signup_.TandC.$error" class="form-error-msg">
                              <span v-if="this.v$.signup_.TandC.$errors[0].$validator == 'isTrue'">Terms is required</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-grid gap-2 mt-3">
                        <button v-on:disabled="reqProcessing" type="button" v-on:click="signup" class="btn btn-common">Create Account</button>
                        <!-- <input value="Create My Account" name="submit" class="btn btn-common" type="submit"> -->
                      </div>
                      <div class="text-center mt-3 text-dark text-16">Already have account? <a data-bs-dismiss="modal" href="#" @click.prevent="openModal('#loginModal')">Sign In Now!</a></div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- End Register Modal -->

    <!-- Forgot Password Modal -->
    <div class="modal fade" id="ForgotPasswordModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ForgotPasswordModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="ForgotPasswordModalLabel">Forgot Password</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="resetPassword">
                      <div class="form-group">
                        <label class="form-label">Email</label>
                        <input type="email" v-model="resetPass_.email"  class="form-control"  placeholder="username@example.com">
                        <div v-if="v$.resetPass_.email.$error" class="form-error-msg-labeled">
                          <span v-if="this.v$.resetPass_.email.$errors[0].$validator == 'email'">Invalid Email</span>
                          <span v-if="this.v$.resetPass_.email.$errors[0].$validator == 'required'">Email is required</span>
                        </div>
                      </div>

                      <div class="d-grid gap-2 mt-3">
                        <input value="Send Reset E-mail" name="submit" class="btn btn-common" type="submit">
                      </div>
                      <div class="text-center mt-3 text-dark text-14">Forget it, Send me back to the <a data-bs-dismiss="modal" @click.prevent="openModal('#loginModal')" href="#">Sign In</a>.</div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {mask} from 'vue-the-mask'
import { VueTelInput } from 'vue-tel-input';
import 'vue-tel-input/dist/vue-tel-input.css';

import useVuelidate from '@vuelidate/core'
import { required, email, sameAs } from '@vuelidate/validators'
import $ from 'jquery'
import axios from 'axios'
import { useToast } from "vue-toastification";

import Notification from "@/components/notifications/Notification"
import MessageNotification from "@/components/notifications/Messages"

const isTrue = (value) => value===false?false:true
export default {
  name: "TheHeader",
  setup () {
    const toast = useToast();
    return { v$: useVuelidate(),toast }
  },
  data() {
    return {
      login_:{
        email:'',
        password:''
      },
      signup_: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        c_password:'',
        gender:'',
        phone:'',
        dialCode:1,
        TandC:false
      },
      resetPass_:{
        email:''
      },
      passwordType:'password',
      reqProcessing:false,
      isActive: true,
      broadcast:[]
    }
  },
  validations () {
    return {
      login_: {
        email: { required },
        password: { required }
      },
      signup_: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        password: { required },
        c_password: { required, sameAsPassword:sameAs(this.signup_.password) },
        gender:{required},
        phone:{required},
        TandC:{isTrue}
      },
      resetPass_:{
        email:{ required, email }
      },
    }
  },
  components: {VueTelInput,Notification,MessageNotification},
  directives: {mask},
  methods: {
    openModal(id){
      $(id).modal('show')
    },
    broadcastInfo(){
        let thiss = this
        axios.get('settings').then(function (response) {
            if (response.data.status == 'success') {
                thiss.broadcast.show_info_text = response.data.data[1].value
                thiss.broadcast.info_text = response.data.data[2].value
                thiss.broadcast.show_info_url = response.data.data[3].value
                thiss.broadcast.info_button_text = response.data.data[4].value
                thiss.broadcast.info_url = response.data.data[5].value
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status == 422) {
                    thiss.toast.error(error.response.data.message);
                }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                    thiss.toast.error(error.response.data.message);
                }
            }
        });
    },
    switchVisibility() {
      this.passwordType = this.passwordType === "password" ? "text" : "password";
    },
    updateCountryCode(data){
        if (data.dialCode) {
            this.signup_.dialCode = data.dialCode
        }
    },
    login(){
      let thiss= this
      this.v$.login_.$touch()
      if (!this.v$.login_.$error) {
          this.reqProcessing = true
          axios.post('login',this.login_).then(function (response) {
              if (response.data.status == 'success') {
                  //console.log(response.data.data)
                  thiss.$storage.setStorageSync("user",response.data.data.user)
                  thiss.$storage.setStorageSync("token",response.data.data.token)
                  thiss.$storage.setStorageSync("business",response.data.data.business)
                  // thiss.$router.push("/")
                  location.reload()
              }else{
                  thiss.reqProcessing = false
              }
          })
          .catch(error => {
              if (error.response) {
                  if (error.response.status == 422) {
                      thiss.toast.error(error.response.data.message);
                  }else if (error.response.status == 401) {
                      thiss.toast.error(error.response.data.message,{position: "top-right"});
                  }
              }
              thiss.reqProcessing = false
          });
      }
    },
    logout(){
        let thiss = this
        axios.get('logout').then(function (response) {
            if (response.data.status == 'success') {
                thiss.$storage.removeStorageSync("user")
                thiss.$storage.removeStorageSync("token")
                thiss.$storage.removeStorageSync("business")
                // thiss.$router.push("/")
                location.reload()
            }
        })
        .catch(error => {
            if (error.response) {
                if (error.response.status == 422) {
                    thiss.toast.error(error.response.data.message);
                }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                    thiss.$storage.removeStorageSync("user")
                    thiss.$storage.removeStorageSync("token")
                    thiss.$storage.removeStorageSync("business")
                    // thiss.$router.push("/")
                    location.reload()
                }
            }
        });
    },
    signup(){
        let thiss = this
        this.v$.signup_.$touch()
        if (!this.v$.signup_.$error) {
            this.reqProcessing = true
            axios.post('signup',this.signup_).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.$storage.setStorageSync("user",response.data.data.user)
                    thiss.$storage.setStorageSync("token",response.data.data.token)
                    thiss.$storage.setStorageSync("business",response.data.data.business)
                    //$("#signupModal").modal('hide')
                    // thiss.$router.push("/")
                    location.reload()
                }else{
                  thiss.reqProcessing = false
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        thiss.toast.error(error.response.data.message);
                    }
                }
                thiss.reqProcessing = false
            });
        }
    },
    resetPassword(){
      this.v$.resetPass_.$touch()
      if (!this.v$.resetPass_.$error) {
          axios.post('resetPassword',this.signup_).then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    openNav() {
      document.getElementById("menuSidepanel").style.width = "100%";
    },
    closeNav() {
      document.getElementById("menuSidepanel").style.width = "0";
    }
  },
  created: function(){
      this.broadcastInfo()
  },
  mounted() {
      if (this.$storage.getStorageSync('user')?.id) {
          window.Echo.channel('notificationChannel') //Should be Channel Name
          .listen('NotificatiionEvent', (e) => {
              console.log(e)
              // this.count = e.count
              // this.notifications = e.text
          });
          // alert(this.$storage.getStorageSync('user').id);
          // window.Echo.private('users.' + this.$storage.getStorageSync('user').id)
          // .notification((notification) => {
          //     console.log("notification recieved", notification);
          //     this.updateNotifications(notification.id);
          // });
      }

      // window.Echo.private('users.111')
      // .notification((notification) => {
      //     console.log("notification recieved", notification);
      // });



      $('#menuSidepanel').click(function(e){
          var posX = e.pageX - $(this).position().left;
          var width = $('.m-nav').width();
          if (posX > width) {
              document.getElementById("menuSidepanel").style.width = "0";
          }
      });
  }
};
</script>
<style scoped>
  .menu-sidebar {
    transition: none !important;
  }
  .vti__dropdown-list{
      width: 221px !important;
      overflow: hidden;
  }
  .vti__dropdown-item{
    padding: 4px 7px !important;
  }
  .vue-tel-input{
      font-size: 0.9375rem;
      line-height: 1.6;
      color: #605e7e;
      height: 39px;
      background-clip: padding-box;
      box-shadow: none;
      border: 1px solid #e8ebf3;
      border-radius: 3px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  .btn-facebook{
    padding: 6px !important;
    border: 1px solid #ccc;
    color: #333333;
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    justify-content: center
  }
  .btn-facebook:hover{
    color: #631a67;
    border: 1px solid #631a67
  }
  .ondaq_business{
    font-weight: bold;
    color: #710599;
  }
  .btn-facebook img{
    width: 25px
  }
  .btn-facebook span{
    margin-top: 2px;
    font-size:16px;
  }
  .btn-google{
    padding: 6px !important;
    border: 1px solid #ccc;
    color: #333333;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center
  }
  .btn-google:hover{
    color: #631a67;
    border: 1px solid #631a67
  }
  .btn-google span{
    margin-top: 2px
  }
    
    .modal-title{
      font-size:22px;
    }
    .modal .form-label, .modal .form-check label{
      font-size: 16px;
    }
    .form-control{
      height: 45px;
      background-color:#f5faff;
    }
    .btn-common{
      padding-top: 0.585rem;
      padding-bottom: 0.585rem;

    }

    @media (max-width: 575px)
    {
      .menu-sidebar a {
        font-size: 19px;
      }
      .modal{
        --bs-modal-margin: 0px;
      }
      .m-nav {
        width: 314px;
      }
    }


   /*.btn-facebook {
    color: #fff;
    background-color: #1c51dc;
    border-color: #1c51dc;
  }
  .btn-facebook:hover {
    color: #fff;
    background-color: #0941d3;
    border-color: #0941d3;
  }
  .btn-facebook.active {
    color: #fff;
    background-color: #0941d3;
    border-color: #0941d3;
  }
  .btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
  }
  .btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #0941d3;
    border-color: #0941d3;
  }
  .btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active, .show>.btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #0941d3;
    border-color: #0941d3;
  }
  .btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus, .show>.btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 2px rgba(59, 89, 152, 0.5);
  } */
</style>
<template>
    <div v-if="$storage.getStorageSync('token')" class="float-end noti-dropdown">
        <button type="button" class="position-relative border-0 bg-transparent" data-bs-toggle="dropdown" aria-expanded="false">
            <Tooltip :tooltipText="'Messages'" position="bottom" style="font-size:15px"><fa :icon="['far','envelope']" class="fs-5 m-0" /></Tooltip>
            <!-- <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">9+</span> -->
        </button>
        <div class="dropdown-menu p-0">
            <div class="list-group">
                <div class="d-flex align-items-center p-3 border-bottom">
                    <div class="col">
                        <h6 class="m-0"> <fa :icon="['far','envelope']" class="fs-6 me-1" /> Inbox &nbsp;<span>(0)</span> </h6>
                    </div>
                    <div class="col-auto">
                        <a href="#!" class="small"> View All</a>
                    </div>
                </div>
                <div class="noti_div">
                    <!-- <div class="no_noti">
                        <img src="@/assets/images/icons/notifications.svg" style="width: 45px">
                        <h6 class="pt-2 text-muted">No Notifications</h6>
                        <p class="text-muted">Browse our amazing catalog of Gigs or offer your talent on Fiverr.</p>
                    </div> -->
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a unread_noti">
                        <div class="d-flex">
                            <div class="avatar-xs me-2 m-auto">
                                <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-0">my-beauty-saloon</h6>
                                <div class="font-size-12">
                                    <p class="lh-1 text-muted">got it. for that you need to provide me access to you application or if you want..</p>
                                    <p class="mb-0 text-black-50">yesterday</p>
                                </div>
                            </div>
                            <div class="flex-grow-2 me-2 mt-1">
                                <fa :icon="['far','envelope']" class="fs-4 text-muted" />
                            </div>
                        </div>
                    </a>
                    <a href="javascript: void(0);" class="list-group-item list-group-item-action noti_a">
                        <div class="d-flex">
                            <div class="avatar-xs me-2 m-auto">
                                <img src="@/assets/images/icons/avatr.png" style="width: 45px">
                            </div>
                            <div class="flex-grow-1">
                                <h6 class="mb-0">absalon</h6>
                                <div class="font-size-12">
                                    <p class="lh-1 text-muted">No problem. Thank you!</p>
                                    <p class="mb-0 text-black-50">4h</p>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <!-- <a href="#" class="list-group-item list-group-item-action" aria-current="true">
                    <div class="d-flex w-100 justify-content-between">
                        <h6 class="mb-1">List group item heading</h6>
                        <small>3 days ago</small>
                    </div>
                    <p class="mb-1">Some placeholder content in a paragraph.</p>
                    <small>And some small print.</small>
                </a> -->
                <div class="p-2 border-top">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Tooltip from "@/components/common/Tooltip.vue"
export default {
    components: {Tooltip}
}
</script>
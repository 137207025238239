import { defineStore } from 'pinia'
export const useBusinessStore = defineStore('business', {
    state: () => ({ 
        hBusinesses:[],
        businessDetail:{
            business_info:{},
            photos:[],
            reviews:[],
            reviewsStats:[],
            employees:[],
            services:[],
            deals:[],

        },
        isLoading: false
    }),
    actions: {
        async getHomeBusinesses(loc='') {
            const resp = await this.axios.get('home_businesses',{params: {location:loc}})
            if (resp.data.status === 'success') {
                this.hBusinesses = resp.data.data
            }
        },
        async getBusinessDetail(slug, tab='', user_id = '') {
            this.isLoading = true;
            const id = this.businessDetail.business_info?.id || '';
            
            const response = await this.axios.get('business_detail/'+slug, {params: { type: tab, id: id, user_id: user_id }});
            
            if (response.data.status === 'success') {
                const { business_info, photos, reviews, reviewsStats, deals, services, employees } = response.data.data;
                if (business_info) {
                    this.businessDetail.business_info = business_info;
                }
                if (photos) {
                    this.businessDetail.photos = photos;
                }
                if (reviews) {
                    this.businessDetail.reviews = reviews;
                }
                if (reviewsStats) {
                    this.businessDetail.reviewsStats = reviewsStats;
                }
                if (deals) {
                    this.businessDetail.deals = deals;
                }
                if (services) {
                    this.businessDetail.services = services;
                }
                if (employees) {
                    this.businessDetail.employees = employees;
                }
            } else if (response.data.status === 'not-found') {
                this.router.push({ name: 'NotFound' });
            }
        
            this.isLoading = false;
        },              

        async toggleFav(business_id,user_id=''){
            const resp = await this.axios.post('do_favourite',{user_id: user_id, business_id: business_id}).catch(error => {return error.response})
            return resp.data
        }
    }
})
<template>
    <section class="header-body py-md-4 py-0" >
        <div class="container">
            <div class="overview-section">
                <div class="row">
                    <div class="col-xl-8 col-lg-7 col-md-6">
                        <div class="salon-overview" v-if="store.businessDetail.business_info">
                            <div class="salon-image">
                                <img v-if="store.businessDetail.business_info.profile_pic" :src="store.businessDetail.business_info.profile_pic" />
                                <img v-else src="https://s3.us-east-2.amazonaws.com/images.ondaq.com/business_logo/default.jpg" />
                                <!-- <div class="back_button d-flex d-md-none">
                                    <router-link :to="'/listing/' + curLocation" class="text-white"><fa icon="angle-left" /></router-link>
                                </div> -->
                                <!-- <div class="image_buttons d-flex d-md-none">
                                    <div class="share_icon">
                                        <a data-bs-toggle="modal" data-bs-target="#shareModal" href="javascript:void(0)" class="text-dark"><fa icon="share" /> </a>
                                    </div>
                                    <div class="favorite_icon">
                                        <favourite :hideText="true" />
                                    </div>
                                </div> -->
                                <!-- <div class="see_photos d-flex d-md-none"><a v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'gallery'}})">Photos <span v-if="store.businessDetail.business_info">({{store.businessDetail.business_info.totalPhotos}})</span></a></div> -->
                            </div>
                            <div class="salon-detail">
                                <h4 class="salon-name">{{store.businessDetail.business_info.title}}</h4>
                                <!-- v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'reviews'}})" -->
                                <div class="salon-rating d-flex">
                                    <span style="padding-top: 7px;padding-right: 5px;">{{store.businessDetail.business_info.rating}}</span>
                                    <star-rating :rating="convertToNumbers(store.businessDetail.business_info.rating)" :title="store.businessDetail.business_info.rating" :round-start-rating="false" :inline="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating>
                                    <span style="padding-top: 8px;padding-left: 5px;">({{store.businessDetail.business_info.total_reviews}})</span>
                                </div>
                                <div class="business_type" v-html="addSeparator(store.businessDetail.business_info.categories)"></div>
                                <div class="opening-status" v-if="store.businessDetail.business_info.is_open > 0"><b>Open</b></div>
                                <div class="closing-status" v-if="store.businessDetail.business_info.is_open === 0"><b>Closed</b></div>
                                <!-- <div class="favorites"><fa icon="heart" /> 5 Favorites</div> -->
                                <div class="salon-address">{{store.businessDetail.business_info.city}}, {{store.businessDetail.business_info.state}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-md-6 d-sm-flex justify-content-center">
                        <!-- <div class="d-sm-none py-3">
                            <button v-if="!$storage.getStorageSync('token')" type="button" class="btn-req-quote rounded-pill " data-bs-toggle="modal" data-bs-target="#loginModal">Request a quote</button>
                            <button v-else type="button" class="btn-req-quote rounded-pill " data-bs-toggle="modal" data-bs-target="#requestModal">Request a quote</button>
                        </div> -->
                        <router-link :to="{name:'BookNow',params:{slug:$route.params.slug}}" class="btn white-button me-3 mt-auto d-none d-sm-block">Book</router-link>
                        <!-- <button type="button" class="btn outling-button d-inline-block d-md-none" data-bs-toggle="modal" data-bs-target="#requestModal">Request a quote</button> -->
                        
                        <button
                            v-if="store.businessDetail.business_info.businessIsOpen === 0"
                            class="btn queue-button-closed mt-auto px-5 d-none d-sm-block"
                            type="button"
                            title="Business is closed"
                            @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                            >
                            Business Closed
                        </button>

                        <button
                            v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 0"
                            class="btn queue-button-closed mt-auto px-5 d-none d-sm-block"
                            type="button"
                            title="Business does not offer Queue"
                            @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                            >
                            Queue Disabled
                        </button>

                        <button
                            v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 1 && store.businessDetail.business_info.queue_walkin === 1"
                            class="btn queue-button-walkin mt-auto px-5 d-none d-sm-block"
                            type="button"
                            title="Business is accepting only walk-in queues"
                            @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                            >
                            Walk-in Queue
                        </button>

                        <button
                            v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 1 && store.businessDetail.business_info.queue_walkin === 0"
                            class="btn queue-button mt-auto px-5 d-none d-sm-block"
                            :class="{ active: $route.params.tab === 'queue' }"
                            type="button"
                            title="Business is accepting virtual queues"
                            @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                            >
                            Live Queue
                        </button>

                        <!-- <a v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'gallery'}})" class="btn outling-button mt-auto d-none d-md-block">See all photos <span v-if="store.businessDetail.business_info">({{store.businessDetail.business_info.totalPhotos}})</span></a> -->
                    </div>
                    <div class="row">
                        <div class="d-sm-none py-3 col-7">
                            <button v-if="!$storage.getStorageSync('token')" type="button" class="btn-req-quote rounded-pill " data-bs-toggle="modal" data-bs-target="#loginModal">Request a quote</button>
                            <button v-else type="button" class="btn-req-quote rounded-pill " data-bs-toggle="modal" data-bs-target="#requestModal">Request a quote</button>
                        </div>
                        <div class="dropup d-sm-none col-5">
                            <button class="btn btn-secondary dropbtn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                <fa icon="plus"/>
                            </button>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <li>
                                    <div class="share_icon">
                                        <a data-bs-toggle="modal" data-bs-target="#shareModal" href="javascript:void(0)" class="text-dark"><fa icon="share" /> </a>
                                    </div>
                                </li>
                                <li>
                                    <div class="favorite_icon">
                                        <favourite :hideText="true" />
                                    </div>
                                </li>
                                <li>
                                    <router-link :to="{name:'BookNow',params:{slug:$route.params.slug}}" class="btn white-button mx-5 mt-auto mb-2 setBtnWidth">Book</router-link>
                                </li>
                                <li>
                                    <button
                                        v-if="store.businessDetail.business_info.businessIsOpen === 0"
                                        class="btn queue-button-closed mt-auto px-5 d-none d-sm-block"
                                        type="button"
                                        title="Business is closed"
                                        @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                                        >
                                        Business Closed
                                    </button>

                                    <button
                                        v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 0"
                                        class="btn queue-button-closed mt-auto px-5 d-none d-sm-block"
                                        type="button"
                                        title="Business does not offer Queue"
                                        @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                                        >
                                        Queue Disabled
                                    </button>

                                    <button
                                        v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 1 && store.businessDetail.business_info.queue_walkin === 1"
                                        class="btn queue-button-walkin mt-auto px-5 d-none d-sm-block"
                                        type="button"
                                        title="Business is accepting only walk-in queues"
                                        @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                                        >
                                        Walk-in Queue
                                    </button>

                                    <button
                                        v-if="store.businessDetail.business_info.businessIsOpen === 1 && store.businessDetail.business_info.queue_status === 1 && store.businessDetail.business_info.queue_walkin === 0"
                                        class="btn queue-button mt-auto px-5 d-none d-sm-block"
                                        :class="{ active: $route.params.tab === 'queue' }"
                                        type="button"
                                        title="Business is accepting virtual queues"
                                        @click="$router.push({name: 'BusinessProfile', params: {slug: $route.params.slug, tab: 'queue'}})"
                                        >
                                        Live Queue
                                    </button>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="dropup d-sm-none">
                        <button class="dropdown-toggle dropbtn" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <fa icon="plus"/>
                        </button>
                        <div class="dropup-content" aria-labelledby="dropdownMenuButton">
                            <div class="image_buttons">
                                <div class="share_icon">
                                    <a data-bs-toggle="modal" data-bs-target="#shareModal" href="javascript:void(0)" class="text-dark"><fa icon="share" /> </a>
                                </div>
                                <div class="favorite_icon">
                                    <favourite :hideText="true" />
                                </div>
                            </div>
                            <router-link :to="{name:'BookNow',params:{slug:$route.params.slug}}" class="btn white-button me-3 mt-auto mb-2">Book</router-link>
                            <button title="Business is closed " v-on:click="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'queue'}})" v-if="store.businessDetail.business_info.businessIsOpen == 0" class="btn queue-button-closed mt-auto px-5" type="button">Closed</button>
                            <button title="Business is accepting only walk-in queues"  v-if="store.businessDetail.business_info.businessIsOpen == 1 &&  store.businessDetail.business_info.queue_status == 0 " v-on:click="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'queue'}})"  class="btn queue-button-walkin mt-auto px-5"  type="button">Walk-in Queue</button>
                            <button title="Business is accept online queues " v-if="store.businessDetail.business_info.businessIsOpen == 1 &&  store.businessDetail.business_info.queue_status == 1" v-on:click="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'queue'}})" class="btn queue-button mt-auto px-5" :class="$route.params.tab == 'queue' ? 'active':''" type="button">Live Queue</button>
                        </div>
                    </div> -->

                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
    import Favourite from '@/components/businessProfile/Favourite.vue'
    import StarRating from 'vue-star-rating'
    import { useBusinessStore } from '@/stores/business'
    import { useRoute } from 'vue-router'
    import { useStorage } from "vue3-storage";

    const store = useBusinessStore()
    const route = useRoute()
    const storage = useStorage()

    let user_id = ''
    if (storage.getStorageSync('user') != undefined && storage.getStorageSync('user') != '') {
        user_id = storage.getStorageSync('user').id
    }
    if (Object.keys(store.businessDetail.business_info).length === 0 && route.name == 'BookNow') {
        store.getBusinessDetail(route.params.slug,'',user_id)
    }

    const convertToNumbers = (data) => {
        return parseInt(data)
    }
    const addSeparator = (categories=[]) => {
        let str = ''
        if (categories.length > 0) {
            for (let index = 0; (index < categories.length) && (index < 4); index++){
                if (index !== 0) {
                    str += ' <span> | </span> ';
                }
                str += categories[index].title;
            }
        }
        return str
    }
</script>
<style scoped>
@media screen and (max-width:576px) {
    .overview-section .salon-name {
        font-size: 18px;
    }
    .business_type{
        display: none;
    }
    .setBtnWidth{
        width: -webkit-fill-available;
    }
    .share_icon{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        padding: 5px 1px;
        position: relative;
        top: 0px;
        left: 90px;
    }
    .favorite_icon{
        background: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50px;
        padding: 6px 0px;
        position: relative;
        top: 0px;
        left: 90px;
        margin: 10px 0;
    }
    .dropdown-menu{
        background-color: #f1f1f1;
    }
    .share_icon a , .favorite_icon a{
        padding: 0px 7px !important;
    }
    /* .image_buttons .favorite_icon{
        margin-top: 4px;
        margin-left:0px
    } */
    .salon-overview{
        display: flex;
    }
    .overview-section{
        text-align: right;
    }
    .overview-section .salon-image{
        width: 60%;
    }
    .salon-image{
        margin-top: 6px;
        height: 125px;
    }
    .salon-detail{
        padding-left:15px;
    }
    /* .image_buttons {
        position: absolute;
        top: -70px;
        right: 70px;
    } */
    button.btn-req-quote{
        padding: 10px 30px;
    }

    .dropbtn {
        background-color: #000;
        color: white;
        padding: 6px 12px;
        font-size: 16px;
        border: none;
        border-radius: 50px;
        position: absolute;
        bottom: 17px;
        right: 90px;
    }
    .dropup .dropdown-toggle::after{
        display:none;
    }

    /*
    .dropup-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        bottom: 60px;
        padding: 10px 0px;
        z-index: 1;
    }

    .dropup-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
        }

    .dropup-content a:hover {background-color: #ccc}

    .dropup:hover .dropup-content {
        display: block;
    }

    .dropup:hover .dropbtn {
        background-color: #000;
    } */
}
</style>
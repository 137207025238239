<template>
    <div class="reviews-content">
        <div class="reviews-header">
            <div class="row" v-if="reviews_stats">
                <div class="col-12" v-on:click.prevent="$router.push({name:'BusinessProfile',params: {slug:$route.params.slug,tab:'reviews'}})">
                    <h2 class="mb-4 d-flex" >
                        <span class="pt-2 me-3">{{reviews_stats.rating}}</span>
                        <star-rating :rating="parseFloat(reviews_stats.rating)" :star-size="30" :round-start-rating="false" :show-rating="false" :read-only="true"></star-rating>
                        <span class="pt-sm-3 pt-2 ms-2 fs-5 fw-bold">({{reviews_stats.total_reviews}}) <span v-if="reviews_stats.total_reviews > 1">Reviews</span> <span v-else>Review</span></span>
                    </h2>
                </div>
                <div class="col-12 col-md-6">
                    <div class="progress-bar-wrapper">
                        <div class="fc">5</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_five,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_five" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">4</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_four,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_four" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">3</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_three,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_three" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">2</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_two,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_two" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                    <div class="progress-bar-wrapper">
                        <div class="fc">1</div>
                        <div class="sc">
                            <div class="progress">
                                <div class="progress-bar" role="progressbar" :style="{width: getPercentage(reviews_stats.total_one,reviews_stats.total_reviews) + '%' }" :aria-valuenow="reviews_stats.total_one" aria-valuemin="0" :aria-valuemax="reviews_stats.total_reviews"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12" v-if="reviews_stats">
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Quality of Service</span>
                        <div class="sc"><star-rating :rating="parseFloat(reviews_stats.avg_quality)" :title="reviews_stats.avg_quality" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Skill</span>
                        <div class="sc"><star-rating :rating="parseFloat(reviews_stats.avg_skill)" :title="reviews_stats.avg_skill" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Communication</span>
                        <div class="sc"><star-rating :rating="parseFloat(reviews_stats.avg_communication)" :title="reviews_stats.avg_communication" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Adherence to Schedule</span>
                        <div class="sc"><star-rating :rating="parseFloat(reviews_stats.avg_timing)" :title="reviews_stats.avg_timing" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                    <div class="stars-wrapper">
                        <span class="me-3 fc">Would recommend</span>
                        <div class="sc"><star-rating :rating="parseFloat(reviews_stats.avg_recommendation)" :title="reviews_stats.avg_recommendation" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="reviews-inner-body" v-if="reviews">
            <div class="review-box" v-for="review in reviews" :key="review.id">

                <div class="author">
                    <span class="author-image"><img src="@/assets/images/icons/avatr.png" /></span>
                    <span class="author-name">{{review.user.name}}</span>
                    <span class="post-date">May 27, 2021</span>
                </div>

                <div class="review-rating">
                    <star-rating :rating="review.overall_rating" :title="review.overall_rating" :round-start-rating="false" :star-size="18" :show-rating="false" :read-only="true"></star-rating>
                    <p class="mb-2">Service: Full Makeup</p>
                </div>
                <div class="review-description">
                    <p v-if="review.business_review.length > 155"><span>{{review.business_review.substring(0, 155)}}</span><span v-if="readMore">{{review.business_review.substring(155, 4000)}}</span> <a href="#!" @click="readMore =! readMore"><span v-if="readMore">See Less</span><span v-else>See More</span></a></p>
                    <p v-else>{{review.business_review}}</p>
                    <div class="review-help-btn pt-2">
                        <p class="mb-0 text-muted"><span id="helpfulCounter_{review.id}">15</span> people found this helpful</p>
                        <button id="helpful" class="btn help-button shadow-sm"  v-on:click="helpFulReview(review.id,review.business_id)" >Helpful</button>
                        <button class="btn report-button" v-on:click="reportReview(review.id,review.business_id)">Report</button>
                    </div>
                </div>
            </div>
            <!-- Reviews Box -->
        </div>
        <!-- Reviews Inner Body -->
    </div>
</template>

<script>
    import $ from 'jquery'
    import StarRating from 'vue-star-rating'
    import axios from "axios"

    export default {
        props: {reviews:Array,reviews_stats:Object},
        components: {
            StarRating
        },
        data() {
            return {
                readMore: false
            }
        },
        methods:{
            getPercentage(obtain,total){
                return (obtain/total)*100;
            },
            helpFulReview(review_id,business_id){
               let thiss = this
                axios.post('review_helpful',{'review_id':review_id,'business_id':business_id}).then(function (response) {
                    if (response.data.status == 'success') {
                      console.log(response.data.data);
                      $('#helpful').attr('disabled','disabled');
                       $('#helpfulCounter_'+review_id).html(response.data.data);
                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            },
            reportReview(review_id,business_id){
               let thiss = this
                axios.post('review_report',{'review_id':review_id,'business_id':business_id}).then(function (response) {
                    if (response.data.status == 'success') {
                      console.log(response.data.data);
                      $('#helpful').attr('disabled','disabled');
                       $('#helpfulCounter_'+review_id).html(response.data.data);
                    }else if (response.data.status == 'login') {
                        thiss.$storage.removeStorageSync("user")
                        thiss.$storage.removeStorageSync("token")
                        thiss.$storage.removeStorageSync("business")
                        thiss.$router.push({ name: 'Home'})
                    }
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == 422) {
                            this.toast.error(error.response.data.message);
                        }else if (error.response.status == 401) {
                            this.toast.error(error.response.data.message,{position: "top-right"});
                        }
                    }
                });
            }
        }
    }
</script>

<style>
    .progress{
        height:10px
    }
    .progress-bar-wrapper,.stars-wrapper{
        width: 100%;
        display: flex;
        margin-bottom: 5px;
    }
    .progress-bar-wrapper .fc{
        width: 30px;
        float: left;
    }
    .progress-bar-wrapper .sc{
        width: calc(85% - 30px);
        float: left;
        padding-top: 6px;
    }
    .stars-wrapper .fc{
        width:60%;
    }
    .stars-wrapper .sc{
        width:40%;
        margin-top: -2px;
    }
</style>